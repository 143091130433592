<div class="navbar">
  <div class="navbar__menu--container">
    <a
      *ngFor="let link of links"
      class="navbar__menu"
      [routerLink]="link.url"
      routerLinkActive="navbar__menu--active"
    >
      <fa-icon class="navbar__icon" [icon]="link.icon"></fa-icon>
    </a>
  </div>
</div>

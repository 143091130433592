<div class="card profile">
  <div class="profile__img">
    <img class="profile__img--pic" [src]="image" />
  </div>
  <h1 class="profile__name">{{ name }}</h1>
  <p class="profile__job">{{ job }}</p>
  <p class="profile__email">{{ email }}</p>
  <div class="profile__social">
    <button
      class="profile__icon"
      *ngFor="let icon of socialLinks"
      (click)="navigate(icon.url)"
    >
      <fa-icon
        [icon]="icon.icon"
        [styles]="{ width: '2.5rem', height: '2.5rem' }"
      ></fa-icon>
    </button>
  </div>
</div>

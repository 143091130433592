<nav class="navbar">
  <div class="navbar__logo" *ngIf="logo">
    <img class="navbar__logo--img" [src]="logo" />
  </div>
  <a
    *ngFor="let link of links"
    [routerLink]="link.url"
    class="navbar__item"
    routerLinkActive="navbar__item--active"
  >
    <fa-icon
      *ngIf="link.icon"
      [icon]="link.icon"
      size="lg"
      class="navbar__item--icon"
    ></fa-icon>
    <h3 class="navbar__item--text">{{ link.title }}</h3>
  </a>
</nav>

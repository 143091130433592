<div class="projects">
  <div class="projects__grid">
    <div class="projects__grid--item" *ngFor="let project of projects">
      <hewitson-dev-project-card
        [project]="project"
        [displayIcons]="false"
      ></hewitson-dev-project-card>
    </div>
  </div>
</div>

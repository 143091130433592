<a
  class="tag"
  [style]="{
    'background-color': colour,
    color: textColour,
    'border-color': colour,
    'font-size': size,
    'border-radius': size
  }"
  [routerLink]="'/tags/' + name"
>
  {{ name }}
</a>
